import { Component, OnInit, ChangeDetectorRef, ElementRef, ViewChild } from '@angular/core';
import { FormControl, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { MatDialogRef, DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS, MatDatepicker, MatDialog } from '@angular/material';
import { LandingPageComponent } from '../landing-page/landing-page.component';
import { FaxnowService } from '../services/faxnow.service';
import * as _moment from 'moment'
import { Subscription } from 'rxjs';
import { default as _rollupMoment, Moment } from 'moment';
import { StripeService, Elements, Element as StripeElement, ElementsOptions } from "ngx-stripe";

const moment = _rollupMoment || _moment;

declare var $: any;



@Component({
  selector: 'app-payment-page',
  templateUrl: './payment-page.component.html',
  styleUrls: ['./payment-page.component.css'],
  providers: [
  ],
})
export class PaymentPageComponent implements OnInit {
  elements: Elements;
  card: StripeElement;
  showSpinnerStatus :boolean;
  // optional parameters
  elementsOptions: ElementsOptions = {
    locale: 'en'
  };

  stripeTest: FormGroup;
  date = new FormControl(moment());
  subscription1: Subscription
  message: any = [];
  FaxTime: any;
  base64: any = [];
  DocFile: any = [];
  filebase64: any = [];
  paymentId: any;
  imageBase64: any = [];
  sendImage: any = [];

  constructor(private fb: FormBuilder, public router: Router, public dialogRef: MatDialogRef<LandingPageComponent>,
    public dialogRef1: MatDialogRef<PaymentPageComponent>,
    public faxService: FaxnowService,
    public dialog: MatDialog,
    private stripeService: StripeService
  ) {
    this.showSpinnerStatus = true;
    this.faxService.showSpinner();
    setTimeout(() => {
      this.faxService.hideSpinner();
    }, 5000);
    this.subscription1 = this.faxService.getMessageLanding().subscribe(msg => {
      this.message = msg.text
    });
  }

  ngOnInit() {

    this.stripeTest = this.fb.group({
      name: new FormControl('', Validators.compose([Validators.required, Validators.pattern(/^([a-z']+(-| )?)+$/i)]))
    });
    this.showModal();    
  }
  showModal() {
    this.stripeService.elements(this.elementsOptions)
      .subscribe(elements => {
          this.elements = elements;
        // Only mount the element the first time
        if (!this.card) {
          this.card = this.elements.create('card', {
            hidePostalCode: true,
            style: {
              base: {
                color: 'rgb(33, 33, 33)',
                fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                fontSmoothing: 'antialiased',
                fontSize: '16px',
                '::placeholder': {
                  color: '#aab7c4'
                }
              },
              invalid: {
                color: '#fa755a',
                iconColor: '#fa755a'
              }
            }
          });

          this.card.mount('#card-element');
        }
      });
  }
  paymetPage() {
    if(navigator.onLine){
      this.imageBase64 = [];
    this.sendImage = []
    if (this.stripeTest.valid) {
      this.showSpinnerStatus = false
      this.faxService.showSpinner();
      this.stripeService
        .createToken(this.card, {})
        .subscribe(result => {
          if (result.token) {
            this.imageBase64 = this.message.base64
            this.imageBase64.forEach(element => {
              var data = element.base64Value.split(",")
              let item = ({
                "url": element.url,
                "base64": data[1]
              })
              this.sendImage.push(item)
            });
            let paramsData = {
              senderId: this.message.Id,
              recipientId: this.message.reciId,
              token: result.token.id,
              amount: this.message.totalCost,
              description: "Fax_Now",
              TimeZoneOffset: new Date().getTimezoneOffset(),
              // TotalPages: this.message.totalPage,
              totalPage: this.message.totalPage,
              DialNumber: this.message.sendNumber,
              faxDetails: this.sendImage
            }
            this.faxService.postApi('user/paymentWEB', paramsData, 1).subscribe((paymentdata) => {
              this.faxService.hideSpinner();
              if (paymentdata.responseCode == "200") {
                this.paymentId = paymentdata.data.paymentId
                this.FaxTime = paymentdata.data.createdAt
                localStorage.setItem('jobId',paymentdata.data.faxDetails[0].JobId)
                localStorage.setItem('FaxTime', this.FaxTime)
                this.faxService.showSuccess('Your fax is being processed. Please check your mail for confirmation.')
                this.dialogRef.close();
                this.faxService.paymentStatus = 1;

              } else if(paymentdata.responseCode == 404) {
                this.faxService.paymentStatus = 2;
                this.faxService.toastErr(paymentdata.responseMessage)
                this.dialogRef.close();
              }else{
                this.faxService.paymentStatus = 2;
                this.faxService.toastErr('Transaction failed!')
                this.dialogRef.close();
              }
            },error=>{
              this.faxService.hideSpinner();
            })

          } else if (result.error) {
            this.faxService.hideSpinner();
            this.faxService.toastErr('All fields are required!')
          }
        });
    } else {
      this.faxService.toastErr('All fields are required!')
    }
    }else{
      this.router.navigate(['**'])
    }
  }

  onNoClick() {
    this.faxService.paymentStatus = 0;
    this.dialogRef1.close();
  }



}
