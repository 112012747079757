import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrManager } from 'ng6-toastr-notifications'

@Injectable({
  providedIn: 'root'

})
export class FaxnowService {
  private send_Form = new Subject<any>();
  private paymentData: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  // baseURl= 'http://172.16.1.7:8080/';
  // baseURl = 'http://172.16.6.74:1519/'; // Pramod Sir
  // baseURl = 'http://ec2-35-176-66-190.eu-west-2.compute.amazonaws.com:1519/' //staging URl
  // baseURl = 'http://182.72.203.245:1809/' //staging URl
  // baseURl = 'http://35.202.68.144:1809/' 
  baseURl = 'https://admin.faxnow.app/';

  // baseURl = 'http://172.16.16.177:1519/'; // Rajat 
  // baseURl = 'http://172.16.6.162:1519/'

  paymentStatus: Number = 0;


  constructor(public http: HttpClient, private spinner: NgxSpinnerService, private toastr: ToastrManager) { }

  postApi(url, data, headers): Observable<any> {
    return this.http.post(this.baseURl + url, data, headers)
  }
  // pramod sir api 
  post(url, data): Observable<any> {
    return this.http.post(url, data)
  }

  getApi(url, header) {
    return this.http.get(this.baseURl + url, header);
  }
  // ------------ Toaster-----------------
  showSuccess(msg) {
    this.toastr.successToastr(msg)
  }
  toastErr(msg) {
    this.toastr.errorToastr(msg)
  }
  showWarning(msg) {
    this.toastr.warningToastr(msg);
  }
  showToast(position: any = 'top-left') {
    this.toastr.infoToastr('This is a toast.', 'Toast', {
      position: position
    });
  }
  // ----------------- to get Country code data ------------
  getCountry() {
    return this.http.get('../../assets/county.json');
  }

  /** to subscribe using observable */
  sendMessage(msg: string, msg1: string, msg2: string, msg3: string) {
    this.send_Form.next({ text: msg, text1: msg1, text2: msg2, text3: msg3 });
  }

  /** to get message */
  getMessage(): Observable<any> {
    return this.send_Form.asObservable();
  }
  /** to subscribe using observable */
  public sendMessageLanding(msg: any) {
    this.paymentData.next({ text: msg });
  }

  /** to get message */
  public getMessageLanding(): Observable<any> {
    return this.paymentData.asObservable();

  }

  showSpinner() {
    this.spinner.show();
  }

  hideSpinner() {
    this.spinner.hide();
  }

  sendCaptchaToken(token) {
    return this.http.post<any>(this.baseURl + "captcha/verifyCaptchatoken", { recaptcha: token })
  }

}
