import { Component } from '@angular/core';
import 'hammerjs';
import { ConnectionService } from 'ng-connection-service';
import { FaxnowService } from './services/faxnow.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'FaxNowWeb';
  status = 'ONLINE'; //initializing as online by default
  isConnected = true;

  constructor(private connectionService:ConnectionService,private service:FaxnowService){
    this.connectionService.monitor().subscribe(isConnected =>{
      this.isConnected = isConnected;
      if(this.isConnected){
        this.status = "ONLINE";
        this.service.hideSpinner()
      } else {
        this.service.showSpinner()
        this.status = "OFFLINE"
      }
      
    });
  }
  
}
