import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';
import { Subscription } from 'rxjs';
import { CoverPagesComponent } from 'src/app/cover-pages/cover-pages.component';
import { PaymentPageComponent } from 'src/app/payment-page/payment-page.component';
import { environment } from 'src/environments/environment';
import { FaxnowService } from '../services/faxnow.service';
// import { SearchCountryField, TooltipLabel, CountryISO } from 'ngx-intl-tel-input';


declare var $: any;
@Component({
    selector: 'app-landing-page',
    templateUrl: './landing-page.component.html',
    styleUrls: ['./landing-page.component.css']
})
export class LandingPageComponent implements OnInit {
    siteKey = environment.capchaSiteKey;
    NewcountryList: any = [];
    selectedIndex: number;
    payTab: boolean;
    matTwo: boolean = false;
    matThree: boolean = false;
    matFour: boolean = false;
    index: number = 0;
    uploadForm: FormGroup;
    payForm: FormGroup;
    matOne: boolean = true;
    uploaddata: any;
    data: any;
    countrydata: string[];
    countrycode: any;
    dialogRef: any;
    myList: any;
    numberRequired: boolean = false;
    emailRequired: boolean = false;
    utcDate: Date;
    utc: number;
    nd: Date;
    uploadclass: boolean = false;
    array: any = [];
    arraydata: any = [];
    imageFile: any;
    countryCode;
    spinner: any;
    pdfUrl: any;
    addarticle: any = [];
    urldata: any = [];
    newArray: any = [];
    subscription: Subscription
    message: any;
    coverdata: any;
    page: any = [];
    countryData: any;
    countryCodeValue: string;
    senderId: any;
    recipientId: any;
    noOfPage: any;
    arrayPdfUpdate: any = [];
    newPage: any = [];
    newArrayPdfUpdate: any = [];
    PDFarray: any = [];
    message1: any;
    arrayPdfUpdate1: any = [];
    message2: any;
    messageName: any = [];
    currenTime: string;
    totalDoc: any = [];
    totalCost: number;
    costCountry: any;
    arrayPage: any = [];
    base64Arr: any = [];
    addPage: number;
    arrayPdfUpdate2: any;
    page1: any;
    addPages: number;
    faxDoc: any = [];
    addRecipient: any = [];
    country: any = [];
    costPerPage: any;
    handler: any = null;
    paymentPdf: any = [];
    paymentPdfBase64: any = [];
    message3: any = [];
    paymentPdfBase: any = [];
    faxJobId: string;
    isCaptchaVerify = false;
    constructor(public dialog: MatDialog, private fb: FormBuilder, private httpService: HttpClient, public faxService: FaxnowService, private router: Router) {
        this.subscription = this.faxService.getMessage().subscribe(message => {
            this.message = message.text
            this.message1 = message.text1
            this.message2 = message.text2;
            this.message3 = [{ base64Value: message.text3, url: message.text1 }]
            this.addToataPages()
        });
    }

    // ----------- Cover Page Modal---------------
    openDialog(): void {
        this.dialog.open(CoverPagesComponent, {

        });
        localStorage.setItem('email', this.uploadForm.value.email);
    }

    ngOnInit() {
        this.getcountry();
        this.uploadForm = this.fb.group({
            'code': new FormControl('+1', Validators.compose([Validators.required])),
            'faxNumber': new FormControl('', Validators.compose([Validators.required, Validators.minLength(7), Validators.maxLength(18), Validators.pattern(/^-?(0|[1-9]\d*)?$/)])),
            'email': new FormControl('', Validators.compose([Validators.required, Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/)])),
            'recaptchaReactive': new FormControl('', Validators.required)
        })
        this.payForm = this.fb.group({
            'faxNumber': new FormControl(''),
            'pageNumber': new FormControl(''),
            'perPage': new FormControl(''),
            'cost': new FormControl(''),
            'totalCost1': new FormControl(''),
        });
        localStorage.setItem('email', this.uploadForm.value.email);
    }



    // --------------------- Get country code JSON file --------------
    getcountry() {
        if (navigator.onLine) {
            this.faxService.showSpinner();
            let country = {
                "userType": "WEB"
            }
            this.faxService.postApi('admin/getCodeManagement', country, 1).subscribe((country: any) => {
                this.faxService.hideSpinner();
                if (country.responseCode == "200") {
                    this.country = country.result.success
                }

            })
        } else {
            this.router.navigate(['**'])
        }
    }

    //  ---------------- only press number-------------
    numberOnly(event): boolean {
        const charCode = (event.which) ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false;
        }
        return true;
    }

    //  --------------- Select index------------------
    select(event) {
        this.index = event.index;
        if (this.index == 0) {
            this.matOne = true;
            this.matTwo = false;
            this.matThree = false;
            this.addToataPages()
        }
    }


    //   ----------------- navigation tabs----------------
    nextStep(index?) {
        if (navigator.onLine) {
            if (this.matOne) {
                if (this.uploadForm.valid) {
                    ///////---------------addRecipient API Integrate state part--------------
                    if (this.arrayPdfUpdate.length == this.PDFarray.length && this.PDFarray != '') {
                        this.faxService.showSpinner();
                        this.paymentPdfBase = [];
                        if (this.message == undefined) {
                            this.paymentPdfBase = this.paymentPdfBase64;
                            let addRecipient1 = {
                                recipientCountryCode: this.uploadForm.value.code,
                                recipientFaxNumber: this.uploadForm.value.faxNumber,
                                senderEmail: this.uploadForm.value.email,
                                doc: this.arrayPdfUpdate,
                                page: this.addPage,
                                pageArray: this.page,
                            }
                            this.addRecipient = addRecipient1;
                        } else {
                            this.paymentPdfBase = this.message3.concat(this.paymentPdfBase64)
                            this.arrayPdfUpdate1 = this.message1.concat(this.arrayPdfUpdate)
                            let addRecipient2 = {
                                recipientCountryCode: this.uploadForm.value.code,
                                recipientFaxNumber: this.uploadForm.value.faxNumber,
                                senderEmail: this.uploadForm.value.email,
                                doc: this.arrayPdfUpdate1,
                                page: this.addPage,
                                pageArray: this.arrayPage,
                                senderName: this.message.senderName,
                                comment: this.message.comment,
                                subject: this.message.subject,
                            }
                            this.addRecipient = addRecipient2
                        }
                        this.faxService.postApi('web/addRecipient', this.addRecipient, 1).subscribe((addRecipient) => {
                            this.faxService.hideSpinner();
                            if (addRecipient.responseCode == "200") {
                                this.faxService.showSuccess('Recipient has been added successfully.')
                                this.noOfPage = addRecipient.success.page
                                this.senderId = addRecipient.success.senderId
                                this.recipientId = addRecipient.success.recipientId
                                this.faxDoc = addRecipient.success.doc
                                this.paymentData()
                                this.index = 1
                                this.matOne = false;
                                this.matTwo = true;
                                this.matThree = false;
                                this.uploadclass = false;
                                this.payForm.patchValue({
                                    'pageNumber': this.addPage,
                                    'faxNumber': this.uploadForm.value.code + this.uploadForm.value.faxNumber,
                                    'perPage': ' $ ' + this.costPerPage,
                                    'cost': ' $ ' + this.costCountry,
                                    'totalCost1': ' $ ' + this.totalCost
                                });
                            } else if (addRecipient.responseCode == "205") {
                                this.faxService.showSuccess('Updated successfully.')
                                this.noOfPage = addRecipient.success.page
                                this.senderId = addRecipient.success.senderId
                                this.recipientId = addRecipient.success.recipientId
                                this.faxDoc = addRecipient.success.doc
                                this.paymentData()
                                this.index = 1
                                this.matOne = false;
                                this.matTwo = true;
                                this.matThree = false;
                                this.uploadclass = false;
                                this.payForm.patchValue({
                                    'pageNumber': this.addPage,
                                    'faxNumber': this.uploadForm.value.code + this.uploadForm.value.faxNumber,
                                    'perPage': ' $ ' + this.costPerPage,
                                    'cost': ' $ ' + this.costCountry,
                                    'totalCost1': ' $ ' + this.totalCost
                                });
                            }
                        },
                            (err) => {
                            }
                        )
                    } else if (this.arrayPdfUpdate.length == 0) {
                        this.faxService.toastErr('Please upload Pdf file !')
                    } else {
                        this.faxService.showWarning('PDF files uploading !')
                    }
                    //////-----------------addRecipient file API Integrate end part-----------
                } else {
                    this.faxService.toastErr('Please fill all fields !')
                }

            } else if (this.matTwo) {
                this.dialogRef = this.dialog.open(PaymentPageComponent, {
                    width: '500px',
                    disableClose: true
                });
                this.dialogRef.afterClosed().subscribe(result => {
                    if (this.faxService.paymentStatus == 1) {
                        this.index = 2;
                        this.matOne = false;
                        this.matTwo = false;
                        this.matThree = true;
                        // ----------to get Current Time-----------
                        this.currenTime = localStorage.getItem('FaxTime')
                        this.faxJobId = localStorage.getItem('jobId');
                    } else if (this.faxService.paymentStatus == 2) {
                        this.index = 2;
                        this.matOne = false;
                        this.matTwo = false;
                        this.matThree = true;
                    }
                });

            } else if (this.matThree) {
                this.index = 0;
                this.matOne = false;
                this.matTwo = false;
                this.matThree = false;
                this.uploadForm.reset();
                this.payForm.reset();
                this.uploadclass = false;
                this.uploadForm.patchValue({
                    'code': '+1',
                })
                this.array.splice(index);
                this.arrayPdfUpdate.splice(index)
                this.page.splice(index)
                this.addarticle.splice(index);
                this.noOfPage = 0;
                this.addPage = 0;
                this.totalCost = 0
                this.message = undefined;
                this.message1 = undefined;
                this.message2 = undefined;
                this.array = [];
                this.newArray = [];
                this.PDFarray = [];
                this.paymentPdfBase64 = []
                localStorage.clear();
            }
        } else {
            this.router.navigate(['**'])
        }
    }
    // to get total cost event  --------
    changeCountryCost(event) {
        if (navigator.onLine) {
            if (this.addPage > 0) {
                this.faxService.showSpinner();
                let costData = {
                    countryCode: this.uploadForm.value.code,
                    pages: this.addPage,
                }
                this.faxService.postApi('user/pricingPopUp', costData, 1).subscribe((costData: any) => {
                    this.faxService.hideSpinner();
                    if (costData.responseCode == '200') {
                        this.totalCost = costData.data.total;
                        this.costCountry = (costData.data.countryCost * this.addPage).toFixed(2);
                        this.costPerPage = (parseFloat(costData.data.pricePerPage) * this.addPage).toFixed(2);
                        // TODO: Calculate a prorated per-page cost including
                        // flat fee, or show flat fee separately from the
                        // per-page cost in the UI.
                        //
                        // var flatFee = parseFloat(costData.data.flatFee);
                    } else {
                        this.totalCost = 0
                        this.faxService.toastErr(costData.responseMessage)
                    }
                })
            } else {
                this.totalCost = 0
            }
        } else {
            this.router.navigate(['**'])
        }
    }
    // ---------------------to get broswoer pdf file ------------------

    public files: NgxFileDropEntry[];
    async dropped(files: NgxFileDropEntry[]) {
        this.files = files;
        for (const droppedFile of files) {
            if (droppedFile.fileEntry.isFile) {
                const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
                fileEntry.file(async (file: File) => {
                    if (file.type == "application/pdf") {
                        let obj = {};
                        obj = {
                            'name': file.name,
                            'size': file.size
                        }
                        this.array.push(obj)
                        const reader = new FileReader();
                        reader.onload = this.handleReaderLoaded.bind(this);
                        reader.readAsBinaryString(file);
                    } else {
                        this.faxService.toastErr('Please upload only PDF files.')
                    }
                });
            } else {
                // droppedFile.fileEntry as FileSystemDirectoryEntry;
            }

        }
    }

    async handleReaderLoaded(e) {
        if (navigator.onLine) {
            // to convert base 64 
            await this.base64Arr.push({
                'base': 'data:application/pdf;base64,' + btoa(e.target.result),
                'size': e.total
            })
            ///////---------------Browse file API Integrate state part--------------
            if (this.base64Arr.length == this.array.length) {
                this.faxService.showSpinner();
                // to sort by file size
                await this.base64Arr.sort((a, b) => {
                    return a['size'] - b['size']
                })
                await this.base64Arr.forEach(element => {
                    this.addarticle.push(element.base)
                });
                // to sort array by file name
                this.array.sort((a1, b1) => {
                    return a1['size'] - b1['size']
                })
                let j
                while ((j = this.addarticle.shift()) !== undefined) {   // to move array of data 
                    await this.newArray.push(j);
                }
                let data = {
                    doc: this.newArray,
                    docType: "PDF"
                }
                this.faxService.postApi('web/addDocument', data, 1).subscribe((data: any) => {
                    this.faxService.hideSpinner();
                    this.paymentPdf = [];
                    this.page1 = [];
                    if (data.responseCode == "200") {
                        this.arrayPdfUpdate2 = data.success1
                        this.paymentPdf = data.base64
                        this.paymentPdf.forEach(element => {
                            this.paymentPdfBase64.push(element)
                        });
                        this.arrayPdfUpdate2.forEach(element => {
                            this.arrayPdfUpdate.push(element);
                        });
                        let i
                        while ((i = this.array.shift()) !== undefined) {   // to move array of data 
                            this.PDFarray.push(i);
                        }
                        this.page1 = data.pages
                        this.page1.forEach(element => {
                            this.page.push(element);
                        });
                        if (this.arrayPdfUpdate.length == this.PDFarray.length) {
                            this.newArray = []
                        }
                        this.base64Arr = []
                        this.addToataPages()
                        this.changeCountryCost(event);
                        this.faxService.showSuccess('PDF file has been uploaded successfully.')
                    } else if (data.responseCode == "204") {
                        this.faxService.toastErr(data['responseMessage'] + '!')
                    } else if (data.responseCode == "403") {
                        this.faxService.showWarning(data['responseMessage'] + '!');
                        if (this.arrayPdfUpdate.length == 0) {
                            this.array = [];
                            this.base64Arr = []
                            this.addarticle = [];
                            this.newArray = [];
                            this.PDFarray = [];
                        } else {
                            this.array = []
                            this.base64Arr = []
                            this.addarticle = []
                            this.newArray = []
                            this.PDFarray.splice(this.arrayPdfUpdate.length, this.PDFarray.length - this.arrayPdfUpdate.length)
                        }
                    }
                },
                    (err) => {
                        this.faxService.hideSpinner();
                        // this.newArray.splice(this.arrayPdfUpdate.length, this.newArray.length - this.arrayPdfUpdate.length)
                    }
                )
            }
        } else {
            this.router.navigate(['**'])
        }
    }
    subArrayData1(arg0: string, subArrayData1: any): any {
        throw new Error("Method not implemented.");
    }

    //  ------------- delete Pdf file-------------------------
    deleteRow(index) {
        this.PDFarray.splice(index, 1);
        this.base64Arr.splice(index, 1);
        this.page.splice(index, 1);
        this.addToataPages()
        this.changeCountryCost(event);
        this.addarticle.splice(index, 1);
        this.newArray.splice(index, 1);
        this.arrayPdfUpdate.splice(index, 1);
        this.paymentPdfBase64.splice(index, 1);
    }
    // add no of pages
    addToataPages() {
        if (this.message == undefined) {
            this.addPage = 0;
            for (let i of this.page) {
                this.addPage = i + this.addPage
            }
        } else {
            this.arrayPage = this.page
            this.addPage = 0;
            for (let i of this.page) {
                this.addPage = i + this.addPage
            }
        }
    }
    paymentData() {
        var landingData = {
            sendNumber: this.uploadForm.value.code.concat(this.uploadForm.value.faxNumber),
            Id: this.senderId,
            reciId: this.recipientId,
            base64: this.paymentPdfBase,
            totalPage: this.addPage,
            totalCost: this.totalCost,
            senderId: this.senderId
        }
        this.faxService.sendMessageLanding(landingData)
    }
    logfunction() {
        location.reload();
    }

    //function to resolve the reCaptcha and retrieve a token
    async resolved(captchaResponse: string) {
        await this.sendTokenToBackend(captchaResponse); //declaring the token send function with a token parameter
    }
    //function to send the token to the node server
    sendTokenToBackend(tok) {
        //calling the service and passing the token to the service
        this.faxService.sendCaptchaToken(tok).subscribe(
            data => {
                if (data.success) {
                    this.isCaptchaVerify = true;
                } else {
                    this.isCaptchaVerify = false;
                }
            },
            err => {
                console.log(err)
            },
            () => { }
        );
    }
}
