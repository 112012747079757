import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CoverPagesComponent } from './cover-pages/cover-pages.component';
import { PaymentPageComponent } from './payment-page/payment-page.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';


const routes: Routes = [
  { path: '', redirectTo: '/landing-page', pathMatch: 'full' },
  { path:'landing-page',component:LandingPageComponent},
  { path:'cover-pages',component:CoverPagesComponent},
  { path:'payment-page',component:PaymentPageComponent},
  { path: 'page-not-found', component: PageNotFoundComponent},
  { path: '**', redirectTo: 'page-not-found' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
