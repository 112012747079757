
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

// -------------------Angular Material-------------------------
import { MatButtonModule, MatCheckboxModule } from "@angular/material";
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule, } from '@angular/material/form-field';
import { MatTabsModule } from '@angular/material/tabs';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule, MatSelectModule } from '@angular/material';
import { MatStepperModule } from '@angular/material/stepper';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { NgxFileDropModule } from 'ngx-file-drop';
import { ScrollDispatchModule } from '@angular/cdk/scrolling';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { NgxSpinnerModule } from "ngx-spinner";
import { ToastrModule } from 'ng6-toastr-notifications';
import { MatMenuModule } from '@angular/material/menu';
import { MatAutocompleteModule } from '@angular/material/autocomplete';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from "@angular/common/http";
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CoverPagesComponent } from './cover-pages/cover-pages.component';
import { PaymentPageComponent } from './payment-page/payment-page.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { NgxStripeModule } from 'ngx-stripe';
import { environment } from 'src/environments/environment';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';



@NgModule({
  declarations: [
    AppComponent,
    LandingPageComponent,
    CoverPagesComponent,
    PaymentPageComponent,
    PageNotFoundComponent
  ],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    FormsModule,
    AppRoutingModule,
    MatButtonModule, MatCheckboxModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NoopAnimationsModule, MatToolbarModule, MatCardModule, MatFormFieldModule, MatTabsModule, NgxFileDropModule, ScrollDispatchModule, MatAutocompleteModule,
    MatIconModule, MatInputModule, MatSelectModule, MatStepperModule, MatDialogModule, MatDatepickerModule, NgxIntlTelInputModule,
    NgxSpinnerModule, ToastrModule.forRoot(), MatMenuModule, NgxStripeModule.forRoot('pk_live_DfMZ7RR2C5KpXZdqPi0l1j9400U3moIh8E'),
    RecaptchaModule,
    RecaptchaFormsModule
  ],
  exports: [MatButtonModule, MatCheckboxModule,],
  providers: [LandingPageComponent],

  bootstrap: [AppComponent]
})
export class AppModule { }
// pk_test_2MFs7Xan8YSch0Ki0UGJthg600XhWIOrRL
// pk_test_LF6MCn9QzmOKVUtv76VTv2b3
// pk_test_H8VpCfVPZEiM20Bt3nCTesRL00M9BleyME