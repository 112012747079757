import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { FaxnowService } from '../services/faxnow.service';
import { LandingPageComponent } from '../landing-page/landing-page.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-cover-pages',
  templateUrl: './cover-pages.component.html',
  styleUrls: ['./cover-pages.component.css']
})
export class CoverPagesComponent implements OnInit {
  coverForm: FormGroup;
  data: string;
  subscription: Subscription
  message: any;
  coverPageData: any;
  coverPostion: boolean = false;

  constructor(public landing: LandingPageComponent, public dialogRef: MatDialogRef<CoverPagesComponent>, private fb: FormBuilder, public router: Router, public faxService: FaxnowService
  ) { }

  ngOnInit() {
    this.coverForm = this.fb.group({
      'senderName': new FormControl('', Validators.compose([Validators.required, Validators.pattern(/^(\w+\s?)*\s*$/)])),
      'email': new FormControl({ value: localStorage.getItem('email') ? localStorage.getItem('email') : '', disabled: false }),
      'subject': new FormControl(''),
      'recipientName': new FormControl(''),
      'comment': new FormControl(''),
    })
  }

  characterOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return true;
    }
    return false;
  }

  preventWhitespace(event) {
    if (event.keyCode == 32 && !event.target.value) {
      return false
    }
    return true;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  send_Form() {
    if (navigator.onLine) {
      if (this.coverForm.valid) {
        this.faxService.showSpinner();
        let coverData = {
          senderName: this.coverForm.value.senderName,
          senderEmail: localStorage.getItem('email'),
          subject: this.coverForm.value.subject,
          recipientName: this.coverForm.value.recipientName,
          comment: this.coverForm.value.comment,
        }
        this.faxService.postApi('web/addCover', coverData, 1).subscribe((coverData) => {
          this.faxService.hideSpinner();
          if (coverData.responseCode == "200") {
            this.faxService.showSuccess('Cover page has been added successfully.')
            var coverPageData = coverData.coverpage
            var base = coverData.base64
            var coverName = coverData.coverName
            this.dialogRef.close();
            var coverPage = this.coverForm.value
            this.faxService.sendMessage(coverPage, coverPageData, coverName, base)
            this.coverForm.reset()
          } 
        },
          (err) => {
            this.faxService.hideSpinner();
          }
        )
      }
    } else {
      this.router.navigate(['**'])
    }
  }
}
